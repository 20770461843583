import $ from 'jquery';
import Masonry from 'masonry-layout';
import { bodyFix, bodyFixReset } from './_BodyFixed.js';

$(function () {
	const elem = document.querySelector('.c-gallery');

	if(elem){
		const msnry = new Masonry(elem, {
			itemSelector: '.c-gallery__item',
			columnWidth: 370,
			percentPosition: true
		});
	}

// Lightbox
var $overlay = $('<div id="overlay" class="c-gallery__modalWrap"></div>');
var $inner = $('<div class="c-gallery__modalInner"></div>');
var $image = $("<img class='c-gallery__modalImg' >");
var $prevButton = $('<div id="prevButton" class="c-gallery__modalBtn is-prev"></div>');
var $nextButton = $('<div id="nextButton" class="c-gallery__modalBtn is-next"></div>');
var $exitButton = $('<div id="exitButton" class="c-gallery__modalBtn is-close"></div>');

// Add overlay
$overlay.append($inner.append($image).prepend($prevButton).append($nextButton)).append($exitButton);
$("#gallery_modal").append($overlay);

// Hide overlay on default
$overlay.hide();

// When an image is clicked
$(".c-gallery__item").on("click" , function(event) {
  // Prevents default behavior
  event.preventDefault();
  // Adds href attribute to variable
  var imageLocation = $(this).find('img').attr("src");
  // Add the image src to $image
  $image.attr("src", imageLocation);
  // Fade in the overlay
  $overlay.fadeIn("slow");
	bodyFix();
});

// When the overlay is clicked
$overlay.on("click",function() {
  // Fade out the overlay
  $(this).fadeOut("slow");
	bodyFixReset();
});

// When next button is clicked
$nextButton.on("click",function(event) {
  // Hide the current image
  $("#overlay img").hide();
  // Overlay image location
  var $currentImgSrc = $("#overlay img").attr("src");
  // Image with matching location of the overlay image
  var $currentImg = $('#gallery img[src="' + $currentImgSrc + '"]');
  // Finds the next image
  var $nextImg = $($currentImg.parent().next().find("img"));
	console.log( $currentImg, $nextImg);
  // All of the images in the gallery
  var $images = $("#gallery img");
  // If there is a next image
  if ($nextImg.length > 0) {
    // Fade in the next image
    $("#overlay img").attr("src", $nextImg.attr("src")).fadeIn(800);
  } else {
    // Otherwise fade in the first image
    $("#overlay img").attr("src", $($images[0]).attr("src")).fadeIn(800);
  }
  // Prevents overlay from being hidden
  event.stopPropagation();
});

// When previous button is clicked
$prevButton.on("click",function(event) {
  // Hide the current image
  $("#overlay img").hide();
  // Overlay image location
  var $currentImgSrc = $("#overlay img").attr("src");
  // Image with matching location of the overlay image
  var $currentImg = $('#gallery img[src="' + $currentImgSrc + '"]');
  // Finds the next image
  var $nextImg = $($currentImg.parent().prev().find("img"));
  // Fade in the next image
  $("#overlay img").attr("src", $nextImg.attr("src")).fadeIn(800);
  // Prevents overlay from being hidden
  event.stopPropagation();
});

// When the exit button is clicked
$exitButton.on("click",function() {
  // Fade out the overlay
  $("#overlay").fadeOut("slow");
	bodyFixReset();
});
})
