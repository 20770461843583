import $ from 'jquery';
import simpleParallax from 'simple-parallax-js';


var image = document.getElementsByClassName('is-parallax');
new simpleParallax(image , {
  delay: .6,
	transition: 'cubic-bezier(0,0,0,1)',
  scale: 1.5
});


if ($(".js-animation").length) {
  scrollAnimation();
}
function scrollAnimation() {
  $(window).on("scroll" , function () {
    $(".js-animation").each(function (i) {
      let scrollPosition = $(this).offset().top,
        scroll = $(window).scrollTop(),
        windowHeight = $(window).height();
      if (scroll > scrollPosition - windowHeight + 100) {
        if ($(this).data("anime-delay") !== "undefined") {
          $(this)
            .delay($(this).data("anime-delay"))
            .queue(function () {
              $(this).addClass("is-animated");
            });
        } else {
          $(this).addClass("is-animated");
        }
      }
    });

    $('.c-header').css("left", - $(window).scrollLeft());
  });
}
$(window).trigger("scroll");