import $ from 'jquery';

let map;

function initMap() {
	const iconMarker = '/wp-content/themes/wp/assets/images/common/icon/marker.png'
	const iconMarkerHover = '/wp-content/themes/wp/assets/images/common/icon/marker-hover.png'

	if ($('.js-map1').length) {
		$('.js-map1').each(function () {
			// Init position of map
			let mapLatLng = { lat: 42.98045902909352, lng: 141.56568253163505 };

			// Style maps to back&white
			const grayStyles = [
				{
					featureType: "all",
					stylers: [
						{ saturation: -100 },
						{ lightness: 0 }
					]
				},
			];

			// Init options
			let mapOptions = {
				center: mapLatLng,
				zoom: 17,
				styles: grayStyles,
				language: 'ja',
			}

			map = new google.maps.Map($(this)[0], mapOptions)

			// Set position of Marker icon
			const markerImage = new google.maps.MarkerImage(
				iconMarker,
				new google.maps.Size(25, 25),
				new google.maps.Point(0, 0),
			);

			// Init marker icon
			let markerOptions = {
				map: map,
				position: new google.maps.LatLng(mapLatLng.lat, mapLatLng.lng),
				icon: markerImage,
			}

			let marker = new google.maps.Marker(markerOptions)

			// Change marker icon when hover on map
			$(this).on('mousemove', function () {
				marker.setIcon(iconMarkerHover)

			}).on('mouseleave', function () {
				marker.setIcon(iconMarker)
			})
		})
	}
}

window.initMap = initMap;

