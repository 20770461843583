import $ from 'jquery';
import { bodyFix, bodyFixReset } from './_BodyFixed.js';

$(window).on('scroll' , function(){
  let scrollTop = $(window).scrollTop(),
      header = $('#header')

  if(scrollTop > 0){
    header.addClass('is-active')
  }else{
    header.removeClass('is-active')
  }
});

$(window).on('resize' , function(){
  if($(window).outerWidth() < 768){
    $('#header_nav').css('transition', 'all 0.5s ease-in-out')
  }else{
    $('#header_nav').removeAttr('style');
  }
});


$('.js-toggle').on('click' , function(){
  if($(this).hasClass('is-active')){
    $(this).removeClass('is-active');
    $('#header_nav').removeClass('is-open');
    bodyFixReset();
  }else{
    $(this).addClass('is-active');
    $('#header_nav').addClass('is-open');
    bodyFix();
  }
})